<template>
  <div class="edit-checklistItem">
    <div class="input-group">
      <label for="name">Oppgave</label>
      <input type="text" class="input" name="name" v-model="editableChecklistItem.content[0].text" />
    </div>
    <div class="input-group">
      <label for="name">Utførelse</label>
      <textarea class="input" rows="10" name="description" v-model="editableChecklistItem.content[1].text"></textarea>
    </div>
    <div class="add-image">
      <img v-if="previewImage" width="45" height="45" :src="previewImage" />
      <img v-if="contentImage && !previewImage" width="45" height="45" :src="contentImage" />
      <label :for="editableChecklistItem.itemNumber" class="upload-image">
        <Plus  />
      </label>
      <input
        :id="editableChecklistItem.itemNumber"
        type="file"
        @change="onFileChange($event)"
      />
    </div>
    <span @click="handleRemoveChecklistItem" class="edit-checklistItem_remove"><fa icon="trash" /></span>
  </div>
</template>
<script>
import Plus from '@/assets/plus.svg';
import { mapGetters } from 'vuex';
import Helpers from '@/helpers/helpers';

export default {
  name: 'editChecklistItem',
  components: {
    Plus
  },
  props: {
    checklistItem: Object
  },
  emits: ['editChecklistItemEvent', 'removeCheckListItem', 'imageChanged'],
  data() {
    return {
      editableChecklistItem: {},
      dataPopulated: false,
      previewImage: null,
      image: null
    }
  },
  watch: {
    editableChecklistItem: {
      deep: true,
      immediate: true,
      handler() {
        if (this.dataPopulated) {
          this.$emit('editChecklistItemEvent', this.editableChecklistItem);
        }
      }
    },
    image() {
      this.$emit('imageChanged', this.image);
    }
  },
  computed: {
    ...mapGetters([
      'utilities/storageToken',
    ]),
    contentImage() {
      let content = Object.assign([], this.editableChecklistItem.content);
      
      let imageContent = content.reverse().find((item) => {
        return item.contentTypeId === 5;
      })

      if(imageContent) {
        return `${this.createUrlForCustomSize(imageContent.storageUrl, 'small')}${this['utilities/storageToken']}`;
      }
      
      return false;
    }
  },
  created() {
    this.editableChecklistItem = JSON.parse(JSON.stringify(this.checklistItem))
  },
  mounted() {
    this.dataPopulated = true;
  },
  methods: {
    createUrlForCustomSize: Helpers.createUrlForCustomSize,
    handleRemoveChecklistItem() {
      this.$emit('removeCheckListItem', this.editableChecklistItem);
    },

    onFileChange(event) {
      this.createBase64Image(event.target.files[0]);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
   
      reader.onload = (event) => {
        this.previewImage = event.target.result;

        let splitImage = event.target.result.split(',');

        let savingFileObject = {
          id: this.editableChecklistItem.itemNumber,
          name: fileObject.name,
          base64: splitImage[1]
        }

        this.image = savingFileObject;
      }
      
      reader.readAsDataURL(fileObject);
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-checklistItem {
  position: relative;
  background-color: white;
  border-radius: 12px;
  padding: 1rem;
  input,
  textarea {
    background-color: #eceeed;
  }
  &_remove {
    position: absolute;
    right: 1rem;
    top: .75rem;
    color: $dark-grey;
    cursor: pointer;
    &:hover {
      color: $red;
    }
  }
}
.edit-checklistItem:not(:first-child) {
  margin-top: 1rem;
}

.add-image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  input[type="file"] {
    display: none;
  }
  img {
    border-radius: 8px;
    margin-right: .5rem;
  }
  .upload-image {
    flex: 0 1;
    padding: 1rem 1rem .7rem 1rem;
    background-color: $bodybg;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
</style>
