<template>
  <section class="add-checklist">
    <row container :gutter="20">
      <column :xs="12" :md="12" :lg="12">
        <form>
          <div class="input-group">
            <label for="name">Navn</label>
            <input type="text" class="input" name="name" v-model="newChecklist.name" />
          </div>
          <div class="input-group">
            <label for="name">Beskrivelse</label>
            <textarea class="input" rows="10" name="description" v-model="newChecklist.description"></textarea>
          </div>
          <EditChecklistItem
            @editChecklistItemEvent="newChecklistItem"
            @imageChanged="imageChanged"
            @removeCheckListItem="removeChecklistItem"
            v-for="item in newChecklist.checklistItems"
            :key="item.itemNumber"
            :checklistItem="item"
          ></EditChecklistItem>
        </form>
      </column>
    </row>
    <row container :gutter="20">
      <column :xs="6" :md="6" :lg="6">
        <Button
          @click.prevent="addChecklistItem()"
          symbol="plus"
          size="full"
          text="Sjekkpunkt"
          color="lightgreen"
        ></Button>
      </column>
      <column :xs="6" :md="6" :lg="6">
        <Button
          @click.prevent="createChecklist()"
          size="full"
          text="Lagre"
          color="green"
        ></Button>
      </column>
    </row>
  </section>
</template>
<script>
import ChecklistService from '@/services/ChecklistService';
import EditChecklistItem from '@/components/checklists/EditChecklistItem';
import Button from '@/components/Button';

export default {
  name: 'addChecklist',
  components: {
    EditChecklistItem,
    Button
  },
  props: {
    cabin: Object,
    cabinUserRoleId: Number,
  },
  data() {
    return {
      checklistItemNumber: 1,
      newChecklist: {
        cabinId: this.cabin.id,
        name: '',
        description: '',
        checklistItems: []
      },
      images: []
    };
  },
  methods: {
    createChecklist() {
      if(this.newChecklist.name === '' || this.newChecklist.checklistItems.length < 1) {
        this.$toast.error(`Kunne ikke legge til sjekkliste, du må fylle ut alle feltene og legge til sjekkpunkt`);
        return;
      }
      let content = [
        {
            contentTypeid: 1,
            text: this.newChecklist.description,
            contentLanguageId: 1
          }
      ]
      ChecklistService.addChecklist(
        this.cabin.id,
        this.newChecklist.name,
        content,
        this.newChecklist.checklistItems
      )
      .then(res => {

        if(this.images.length > 0) {
          this.images.map((image) => {
            let item = res.data.checklistItems.find((list) => {
              return list.itemNumber === image.checklistItemId;
            });
            ChecklistService.addChecklistItemContent(
              item.id,
              image.contentLanguageId,
              image.contentTypeId,
              image.text,
              image.contentAsBase64,
              image.contentName,
              image.default,
              image.sequence
            ).then(res => {
              console.log(res);
              console.log('Added Image to Checklist Item')
            })
            .catch(err => {
              console.log(err)
            });
          })
        }

        this.$router.push({ name: 'editChecklists'});
        this.$toast.success(`Ny sjekkliste lagt til`);
      })
      .catch(err => {
        console.log(err);
        this.$toast.error(`Kunne ikke legge til sjekkliste`);
      });
    },
    newChecklistItem(val) {
      let itemIndex = this.newChecklist.checklistItems.findIndex(item => {
        return item.itemNumber === val.itemNumber;
      });

      this.newChecklist.checklistItems.splice(itemIndex, 1, val);
    },
    addChecklistItem() {
      this.newChecklist.checklistItems.push({
        itemNumber: this.checklistItemNumber,
        content: [
          {
            contentTypeid: 2,
            text: '',
            contentLanguageId: 1
          },
          {
            contentTypeid: 1,
            text: '',
            contentLanguageId: 1
          }
        ]
      });
      this.checklistItemNumber++;
    },
    removeChecklistItem(val) {
      let itemIndex = this.newChecklist.checklistItems.findIndex(item => {
        return item.itemNumber === val.itemNumber;
      });

      this.newChecklist.checklistItems.splice(itemIndex, 1);
    },
    imageChanged(val) {

      let content = {
        checklistItemId: val.id,
        contentLanguageId: 1,
        contentTypeId: 5,
        text: val.name,
        contentAsBase64: val.base64,
        contentName: val.name,
        default: true,
        sequence: 0
      }

      let index = this.images.findIndex((item) => {
        return item.checklistItemId === val.id;
      })

      if(index === -1) {
        this.images.push(content);
      } else {
        this.images.splice(index, 0, content);
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.add-checklist {
  @include gutter-padding;
  @media (max-width: $breakpoint-mobile) {
    margin-bottom: 6rem;
  }
  @media (min-width: $breakpoint-mobile) {
    margin: 4rem 0;
  }
}
</style>